import { Injectable } from '@angular/core'
import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Menu, STATUS, SupabaseAuthService } from '@core'
import { catchError, filter, map, tap } from 'rxjs/operators'

import { Observable, of, throwError } from 'rxjs'

import { LocalStorageService } from '@shared'

@Injectable({
    providedIn: 'root',
})
export class LoginService {
    constructor(protected http: HttpClient) {}

    menu(): Observable<Menu[]> {
        return this.http.get<{ menu: Menu[] }>('assets/data/menu.json?_t=' + Date.now()).pipe(
            map((response: { menu: Menu[] }) => response.menu),
            catchError(this.handleError),
        )
    }
    private handleError(error: HttpErrorResponse): Observable<never> {
        console.error('An error occurred:', error)
        return throwError('Something went wrong; please try again later.')
    }
}
