import { createActionGroup, emptyProps, props } from '@ngrx/store'
import { Profile, ProfileDto, ProfileUpdateDto } from '../models/profiles.model'

export const profileActions = createActionGroup({
    source: 'profile',
    events: {
        'Request By Uid': props<{ uid: string }>(),
        'Profile By Uid Success': props<{ profile: Profile }>(),
        'Profile By Uid Failed': (error: any) => ({ error }),
    },
})

export const profileListActions = createActionGroup({
    source: 'profiles',
    events: {
        'Request Profiles': emptyProps(),
        'Request Profiles Success': props<{ profiles: Profile[] }>(),
        'Request Profiles Failed': (error: any) => ({ error }),
    },
})

export const profileCreateActions = createActionGroup({
    source: 'profileCreate',
    events: {
        'Request Create Profile': props<{ dto: ProfileDto }>(),
        'Request Create Profile Success': props<{ profile: Profile }>(),
        'Request Create Profile Failed': (error: any) => ({ error }),
        'Reset Create Profile State': emptyProps(),
    },
})

export const profileUpdateActions = createActionGroup({
    source: 'profileUpdate',
    events: {
        'Request Update Profile': props<{ dto: ProfileUpdateDto }>(),
        'Request Update Profile Success': props<{ success: boolean }>(),
        'Request Update Profile Failed': (error: any) => ({ error }),
        'Reset Update Profile State': emptyProps(),
    },
})

export const profileDetailsActions = createActionGroup({
    source: 'profileDetails',
    events: {
        'Request Profile By Id': props<{ id: number }>(),
        'Request Profile By Id Success': props<{ profile: Profile }>(),
        'Request Profile By Id Failed': (error: any) => ({ error }),
        'Reset Profile By Id State': emptyProps(),
    },
})
