import { Injectable } from '@angular/core'
import { switchMap, tap } from 'rxjs/operators'
import { Menu, MenuService } from './menu.service'
import { SupabaseAuthService } from '@core/services/supabase-auth.service'
import { Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { profileActions } from '../../features/profiles/store/actions'

@Injectable({
    providedIn: 'root',
})
export class StartupService {
    constructor(
        private authService: SupabaseAuthService,
        private menuService: MenuService,
        private router: Router,
        private store: Store,
    ) {}

    /**
     * Load the application only after get the menu or other essential informations
     */
    async load() {
        const { data, error } = await this.authService.session
        console.log('startup')
        console.log(data)
        if (data.session) {
            this.authService.menu().subscribe({
                next: (value) => this.setMenu(value),
            })
            const { user } = data.session
            this.store.dispatch(profileActions.requestByUid({ uid: user.id }))
            await this.router.navigate(['dashboard'])
        } else {
            await this.router.navigate(['auth'])
        }
        this.authService.authChanges((data) => {
            console.log(data)

            if (data === 'SIGNED_OUT') {
                this.router.navigate(['auth'])
            }
        })
    }

    private setMenu(menu: Menu[]) {
        this.menuService.addNamespace(menu, 'menu')
        this.menuService.set(menu)
    }
}
